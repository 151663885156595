import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que al tall presenta abundant làtex blanc opalescent i d’olor forta i característica. Aquest presenta un capell de fins a 9 cm de diàmetre, convex i pla al centre, deprimit amb l’edat. El marge que primer és involut, s’estèn i es presenta posteriorment ondulat. És zonat i de color marró rogenc més fosc o clar per indrets. Davall aquest capell, les làmines tenen nombroses lamel·les, atapeïdes, intervenades i un poc forcades, adnades a un poc decurrents, de color crema que més tard torna vermellós i tacat de rovell. Les espores són crema rosat en massa, de globoses a el·lipsoides, amb berrugues soldades en crestes de 6-9 x 6-7 micres. El peu està ple i després es buida, un poc corbat i de color semblant al capell.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      